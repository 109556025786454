<template>
  <v-main>
    <div>
      <div class="background-element background-image" :style="'background-image: url(' + backgroundUrl + ');'"></div>
      <div class="background-gradient background-element"></div>

      <v-container class="mt-2">

        <v-card v-if="showDebugButtons" color="inherit" outlined class="mb-2">
          <v-card-title class="text-h5">Debug</v-card-title>
          <v-card-text>
            <v-btn @click="startTest" class="mr-2">Start</v-btn>
            <v-btn @click="previousSlide" class="mr-2">Prev</v-btn>
            <v-btn @click="nextSlide" class="mr-2">Next</v-btn>
            <v-btn
                @click="applySampleData([[-1, 0, 1], [0, -1, 1], [1, -1, 0], [1, 0, -1], [0, -1, 1], [-1, 0, 1], [1, 0, -1], [1, 0, 0, 1, 0, 1], [1, 1, 0, 0, 1, 0], [1, 1, 0, 0, 0, 1]])"
                class="mr-2">Sample
            </v-btn>
            <v-btn
                @click="applySampleData([[1,-1,0],[-1,0,1],[1,-1,0],[0,-1,1],[-1,0,1],[1,-1,0],[0,-1,1],[0,1,0,0,1,1],[1,0,1,1,0,0],[1,1,0,0,1,0]])"
                class="mr-2">Blue
            </v-btn>
            <v-btn
                @click="applySampleData([[0,1,-1],[0,1,-1],[1,-1,0],[1,-1,0],[0,1,-1],[0,1,-1],[0,1,-1],[0,0,1,1,0,1],[1,0,1,1,0,0],[1,0,0,1,0,1]])"
                class="mr-2">Green
            </v-btn>
            <v-btn
                @click="applySampleData([[-1,0,1],[1,0,-1],[0,1,-1],[0,1,-1],[-1,1,0],[-1,0,1],[1,0,-1],[1,0,1,1,0,0],[0,1,1,0,0,1],[0,1,1,1,0,0]])"
                class="mr-2">Red
            </v-btn>
            <v-btn
                @click="applySampleData([[-1,1,0],[-1,0,1],[0,-1,1],[0,1,-1],[0,1,-1],[1,-1,0],[0,-1,1],[1,1,1,0,0,0],[1,1,0,0,0,1],[0,1,1,1,0,0]])"
                class="mr-2">22
            </v-btn>
          </v-card-text>
        </v-card>

        <div v-if="currentSlideIndex === -1">
          <v-card class="px-3 mb-2" style="background-color: rgba(255, 255, 255, 1)">
            <v-card-title class="text-h5 justify-center" style="word-break: initial">
              Persönlichkeitstest: Lerne dich selbst besser kennen!
            </v-card-title>
          </v-card>
          <v-row>
            <v-col class="col-12 col-sm-6">
              <v-card>
                <v-img class="white--text align-end" height="200px"
                       src="@/assets/images/personality_test/thinking.jpg">
                  <v-card-title style="word-break: initial">Entdecke dein volles Potenzial</v-card-title>
                </v-img>
                <v-card-text class="text--primary text-body-1">
                  Indem du dich selbst besser verstehst, hast du die Möglichkeit, dich selbst und deine individuellen
                  Stärken und Schwächen besser wahrzunehmen und das volle Potenzial deiner Persönlichkeit zu entfalten.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="col-12 col-sm-6">
              <v-card>
                <v-img class="white--text align-end" height="200px"
                       src="@/assets/images/personality_test/notes.jpg">
                  <v-card-title style="word-break: initial">Erkenne die Ursachen von Erfolgen und Misserfolgen
                  </v-card-title>
                </v-img>
                <v-card-text class="text--primary text-body-1">
                  Durch das Verstehen deiner eigenen Stärken und Schwächen kannst du auch die Ursachen von Erfolgen und
                  Misserfolgen besser verstehen und entsprechend handeln, um Erfolge zu steigern und Misserfolge zu
                  vermeiden.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <v-btn color="primary" @click="startTest">
                    Hier klicken & Test Starten
                    <v-icon right>mdi-send</v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col class="col-12 col-sm-4">
              <v-card style="height: 100%">
                <v-img class="white--text align-end" height="200px"
                       src="@/assets/images/personality_test/road.jpg">
                  <v-card-title style="word-break: initial">Vermeide unerreichbare Ziele</v-card-title>
                </v-img>
                <v-card-text class="text--primary text-body-1">
                  Du kannst dir realistische Ziele setzen und Leitbilder wählen, anstatt unerreichbaren Zielen
                  nachzujagen, die zum Misserfolg führen.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="col-12 col-sm-8">
              <v-card>
                <v-img class="white--text align-end" height="200px"
                       src="@/assets/images/personality_test/brain.jpg">
                  <v-card-title style="word-break: initial">Verstehe deine Persönlichkeitsstruktur</v-card-title>
                </v-img>
                <v-card-text class="text--primary text-body-1">
                  Paul D. MacLean, amerikanischer Hirnforscher, unterschied in seiner Arbeit drei Bereiche des Gehirns:
                  das Stammhirn, welches für die Selbsterhaltung und die grundlegenden Instinkte des Lebens
                  verantwortlich ist, das Zwischenhirn, welches für die Selbstbehauptung und das Überleben durch Kampf
                  oder Flucht zuständig ist und das Großhirn, welches für das Selbstbewusstsein und das planende,
                  vorsorgende Handeln zuständig ist. Diese 3 Areale führen zu bestimmten Persönlichkeitsstrukturen.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <v-btn color="primary" @click="startTest">
                    Hier klicken & Test Starten
                    <v-icon right>mdi-send</v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-card class="mt-2 mb-2" style="background-color: rgba(255, 255, 255, 1)">
            <v-card-text class="black--text text-body-1 ">
              <div class="text-h5 text-center">Bist du RENNFAHRER, KÜNSTLER, NERD oder SHAPE-SHIFTER?</div>
              <div class="d-flex flex-wrap justify-center align-end mt-5 mb-3">
                <v-img :src="require('@/assets/images/personality_test/Rennfahrer.png')" height="190" width="150" contain style="max-width: 150px; max-height: 200px"></v-img>
                <v-img :src="require('@/assets/images/personality_test/Kuenstlerin.png')" height="200" width="150" contain style="max-width: 200px; max-height: 200px"></v-img>
                <v-img :src="require('@/assets/images/personality_test/Nerd.png')" height="185" width="150" contain style="max-width: 150px; max-height: 200px"></v-img>
                <v-img :src="require('@/assets/images/personality_test/Shapeshifter.png')" height="175" width="200" contain style="max-width: 180px; max-height: 200px"></v-img>
              </div>
              <div class="d-flex flex-wrap justify-end align-baseline">
                <div class="text-h6">
                   Finde es jetzt heraus!
                </div>
                <v-btn class="mt-2 ml-4" x-large color="primary" @click="startTest">
                  Test starten
                  <v-icon right>mdi-send</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="slides-container">

          <div>
            <div class="d-flex flex-column align-end">
              <v-card class="mt-2" style="align-self: stretch">
                <v-card-text class="black--text text-body-1">
                  <div class="text-h6">Erklärung</div>
                  <div>
                    Du bekommst nun eine Reihe von Fragen gestellt. Jede Frage hat drei Möglichkeiten zur Auswahl.<br>
                    Bitte wähle für jede Frage die Antwort, die am besten zu dir passt und die Antwort, die am wenigsten
                    zu dir passt.
                  </div>
                </v-card-text>
              </v-card>
              <v-btn color="primary" x-large class="mt-2" @click="nextSlide">
                Weiter
                <v-icon right>mdi-send</v-icon>
              </v-btn>
            </div>
          </div>

          <template v-for="slide in 7">
            <div class="">

              <v-card class="mt-2 mt-md-4">
                <v-card-text class="black--text text-body-1">
                  <div class="text-h6">Aufgabe</div>
                  <PtText section="taskStageOne" />
                </v-card-text>
              </v-card>

              <v-card class="mt-2 mt-md-4">
                <v-card-text class="black--text text-body-1">
                  <div class="text-h6">Situation {{ slide }}</div>
                  <PtText :task-id="slide" section="situation" />
                </v-card-text>
              </v-card>

              <div class="d-flex justify-center mt-2">
                <v-icon>mdi-chevron-down</v-icon>
              </div>

              <div v-for="(option, index) in ['a', 'b', 'c']" :key="option"
                   class="mt-2 mb-4 mb-md-6 d-flex flex-column">
                <v-card class="">
                  <v-card-text class="black--text text-body-1">
                    <div class="text-h6">Aussage {{ option.toUpperCase() }}:</div>
                    <PtText :task-id="slide" :section="option" />
                  </v-card-text>
                </v-card>
                <div class="d-flex justify-center mt-2 mt-md-3">
                  <v-btn class="text-none flex-grow-1 flex-sm-grow-0 mr-1 mr-sm-2 mr-md-3"
                         :color="responses[slide - 1][index] === -1 ? '#f5bfbf' : 'white'"
                         @click="buttonPressedStageOne(index, -1)">
                    Am Seltensten
                  </v-btn>
                  <v-btn class="text-none flex-grow-1 flex-sm-grow-0"
                         :color="responses[slide - 1][index] === 1 ? '#abe1b1' : 'white'"
                         @click="buttonPressedStageOne(index, 1)">
                    Am Häufigsten
                  </v-btn>
                </div>
              </div>

            </div>
          </template>

          <div>
            <div class="d-flex flex-column align-end">
              <v-card class="mt-2 mt-md-4" style="align-self: stretch">
                <v-card-text class="black--text text-body-1">
                  <div class="text-h6">Erklärung</div>
                  <div>
                    Das waren die ersten sieben Aufgaben, die zunächst einmal die Grundrichtung deiner
                    Persönlichkeitsstruktur festlegen sollten.<br>Nun folgen drei weitere Aufgaben zur Präzisierung.
                  </div>
                  <div class="mt-2">
                    Nachfolgend findest du sechs Aussagen. Wähle davon die drei Aussagen aus, die am besten auf dich
                    zutreffen.
                    Du musst immer drei Aussagen auswählen. Eine oder zwei zutreffende Aussagen wirst du sicherlich
                    leicht
                    finden.
                    Wähle dann aus dem Rest die Aussage aus, die noch am ehesten auf dich zutrifft.
                    Manchmal geht es leichter umgekehrt: Scheide die drei Aussagen aus, die am wenigsten auf dich
                    zutreffen und wähle dann die übrig gebliebenen aus.
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>

          <template v-for="slide in 3">
            <div>

              <v-card class="mt-2 mt-md-4">
                <v-card-text class="black--text text-body-1">
                  <div class="text-h6">Aufgabe</div>
                  <PtText section="taskStageTwo" />
                </v-card-text>
              </v-card>

              <div class="d-flex justify-center mt-2">
                <v-icon>mdi-chevron-down</v-icon>
              </div>

              <div v-for="(option, index) in ['a', 'b', 'c', 'd', 'e', 'f']" :key="option" class="mt-2 mb-4 mb-md-6">
                <v-card>
                  <v-card-text class="black--text text-body-1">
                    <div class="text-h6">Aussage {{ option.toUpperCase() }}:</div>
                    <PtText :task-id="slide + 7" :section="option" />
                  </v-card-text>
                </v-card>
                <div class="d-flex justify-center mt-2 mt-md-3">
                  <v-btn class="text-none mr-1" :color="responses[slide + 6][index] === 1 ? '#abe1b1' : 'white'"
                         @click="buttonPressedStageTwo(index)">
                    Das passt zu mir
                  </v-btn>
                </div>
              </div>

            </div>
          </template>

          <div>
            <div class="d-flex flex-column align-end">
              <v-card class="mt-2" style="align-self: stretch">
                <v-card-text class="black--text">
                  <div class="text-h6">Fast geschafft!</div>
                  <div class="text-body-1">
                    Bitte trage deinen Namen und deine E-Mail Adresse ein, sodass wir dir deine persönliche
                    Testauswertung zusenden können.
                  </div>
                  <v-text-field v-model="firstName" class="mt-2" label="Dein Vorname" hide-details></v-text-field>
                  <v-text-field v-model="lastName" class="mt-2" label="Dein Nachname" hide-details></v-text-field>
                  <v-text-field v-model="email" class="mt-3" label="Deine E-Mail Adresse" hide-details type="email">
                  </v-text-field>

                  <v-checkbox v-model="privacyPolicy" hide-details="auto" class="mt-2">
                    <template v-slot:label>
                      <div>
                        Ich willige ein in die
                        <a target="_blank" href="/privacy-policy" @click.stop>Datenschutzerklärung</a>
                      </div>
                    </template>
                  </v-checkbox>

                  <div class="d-flex flex-column">
                    <v-btn class="mt-5 align-self-end" color="primary" :disabled="!emailValid || !firstName || !lastName || !privacyPolicy"
                           @click="sendResults">
                      Ergebnis erhalten
                      <v-icon right>mdi-send</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>

          <div>
            <div class="d-flex flex-column">
              <v-card class="mt-2" style="align-self: stretch">
                <v-card-text class="black--text">
                  <div class="text-h6">Vielen Dank!</div>
                  <div class="text-body-1">
                    Du solltest in Kürze eine E-Mail mit deiner persönlichen Testauswertung erhalten.<br>
                    Buch dir jetzt dein persönliches Auswertungsgespräch:
                  </div>
                </v-card-text>
              </v-card>
              <v-card class="mt-4">
                <iframe src="https://outlook.office365.com/owa/calendar/RoySpitzner@mitNORM.com/bookings/s/YOGRQm90ikeP6dVcEaFuhQ2" width="100%"
                        height="700px" scrolling="yes" style="border:0"></iframe>
              </v-card>
            </div>
          </div>

        </div>

        <div v-if="hintText.length > 0" class="text-body-1 text-center mt-0 d-flex justify-center">
          <v-card color="white" class="">
            <v-card-text class="text-body-12 black--text2 px-3 py-1">
              {{ hintText }}
            </v-card-text>
          </v-card>
        </div>

        <div v-if="currentQuestionIndex !== -1" class="d-flex flex-wrap mb-4 mt-4 mt-sm-6 mr-n2">
          <v-btn class="mr-2 mb-2" :disabled="navigationButtonsDisabled" @click="previousSlide">
            Zurück
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mr-2 mb-2 ml-auto disabled-btn-on-dark-background" color="primary"
                 :disabled="navigationButtonsDisabled || nextQuestionButtonDisabled" @click="nextSlide">
            <span v-if="currentQuestionIndex <= 8">Nächste Frage</span>
            <span v-else>Abschließen</span>
            <v-icon right>mdi-send</v-icon>
          </v-btn>
        </div>

        <!--
        <v-btn v-if="currentSlideIndex > 0" @click="currentSlideIndex = -1">
          Reset
        </v-btn> -->

      </v-container>
    </div>
  </v-main>
</template>

<script>
import PtText from "../components/PtText";
import Vue from "vue";
import socialLoginOutdated from "@/components/SocialLoginOutdated.vue";

export default {
  name: "PersonalityTest",
  components: { PtText },
  data: () => ({
    currentSlideIndex: -1,
    navigationButtonsDisabled: false,
    responses: Array.from({ length: 10 }),
    nextQuestionButtonDisabled: true,
    hintText: "",
    firstName: "",
    lastName: "",
    email: "",
    privacyPolicy: false,
    emailValid: false,
    backgroundUrl: require("@/assets/images/climber_1080.jpg")
  }),
  computed: {
    currentQuestionIndex() {
      if (this.currentSlideIndex <= 0) {
        return -1;
      }
      if (this.currentSlideIndex <= 7) {
        return this.currentSlideIndex - 1;
      }
      if (this.currentSlideIndex <= 11) {
        return this.currentSlideIndex - 2;
      }
      return -1;
    },
    showDebugButtons() {
      return this.$store.state.roles.includes("ROLE_INTERNAL_ACCESS");
    }
  },
  watch: {
    email(newVal) {
      // check if email format is valid
      if (newVal.length > 0) {
        this.emailValid = newVal.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      } else {
        this.emailValid = false;
      }
    }
  },
  created() {
    for (let i = 0; i < 7; i++) {
      Vue.set(this.responses, i, [0, 0, 0]);
    }
    for (let i = 0; i < 3; i++) {
      Vue.set(this.responses, i + 7, [0, 0, 0, 0, 0, 0]);
    }
  },
  methods: {
    startTest() {
      this.nextSlide();
    },
    nextSlide() {
      window.scrollTo(0, 0);
      this.navigationButtonsDisabled = true;
      setTimeout(() => {
        this.navigationButtonsDisabled = false;
      }, 250);
      const currentSlide = this.$el.querySelector(".slides-container").children[this.currentSlideIndex];
      const nextSlide = this.$el.querySelector(".slides-container").children[this.currentSlideIndex + 1];
      if (currentSlide) {
        this.clearClasses(currentSlide);
        currentSlide.classList.add("slide-out-left");
        setTimeout(() => {
          currentSlide.style.display = "none";
        }, 250);
      }
      if (nextSlide) {
        nextSlide.style.display = "block";
        this.clearClasses(nextSlide);
        nextSlide.classList.add("position-right");
        setTimeout(() => {
          nextSlide.classList.add("slide-in");
        }, 10);
      }
      this.currentSlideIndex++;
      this.checkState("slide");
    },
    previousSlide() {
      this.navigationButtonsDisabled = true;
      setTimeout(() => {
        this.navigationButtonsDisabled = false;
      }, 250);
      const currentSlide = this.$el.querySelector(".slides-container").children[this.currentSlideIndex];
      const previousSlide = this.$el.querySelector(".slides-container").children[this.currentSlideIndex - 1];
      if (currentSlide) {
        this.clearClasses(currentSlide);
        currentSlide.classList.add("slide-out-right");
        setTimeout(() => {
          currentSlide.style.display = "none";
        }, 250);
      }
      if (previousSlide) {
        previousSlide.style.display = "block";
        this.clearClasses(previousSlide);
        previousSlide.classList.add("position-left");
        setTimeout(() => {
          previousSlide.classList.add("slide-in");
        }, 10);
      }
      this.currentSlideIndex--;
      this.checkState("slide");
    },
    clearClasses(element) {
      element.classList.remove("slide-in");
      element.classList.remove("slide-out-left");
      element.classList.remove("slide-out-right");
      element.classList.remove("position-right");
      element.classList.remove("position-left");
    },
    buttonPressedStageOne(index, value) {
      const responses = this.responses[this.currentQuestionIndex];
      // set all values that are equal to the current value to 0
      for (let i = 0; i < responses.length; i++) {
        if (responses[i] === value) {
          responses[i] = 0;
        }
      }
      responses[index] = value;
      Vue.set(this.responses, this.currentQuestionIndex, responses);
      this.checkState("button");
    },
    buttonPressedStageTwo(index) {
      const responses = this.responses[this.currentQuestionIndex];
      // toggle value between 0 and 1
      responses[index] = responses[index] === 0 ? 1 : 0;
      Vue.set(this.responses, this.currentQuestionIndex, responses);
      this.checkState("button");
    },
    checkState(initiator) {
      this.hintText = "";
      if (this.currentQuestionIndex === -1) {
        return;
      }
      const responses = this.responses[this.currentQuestionIndex];
      let count = 0;
      for (let i = 0; i < responses.length; i++) {
        if (responses[i] !== 0) {
          count++;
        }
      }
      const requiredCount = this.currentQuestionIndex <= 6 ? 2 : 3;
      const valid = count === requiredCount;
      this.nextQuestionButtonDisabled = !valid;
      if (initiator === "slide") {
        return;
      }
      if (this.currentQuestionIndex <= 6) {
        if (valid) {
          this.hintText = "Sehr gut!";
        } else {
          this.hintText = "Du hast diese Frage noch nicht vollständig beantwortet.";
        }
      } else {
        if (valid) {
          this.hintText = "Sehr gut!";
        } else {
          const diff = requiredCount - count;
          if (diff > 0) {
            this.hintText = "Bitte wähle noch " + diff + " weitere Aussage" + (diff > 1 ? "n" : "") + " aus.";
          } else {
            this.hintText = "Du hast " + (diff * -1) + " Aussage" + (diff < -1 ? "n" : "") + " zu viel ausgewählt.";
          }
        }
      }
    },
    sendResults() {
      const data = {
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        email: this.email.trim(),
        responses: this.responses
      };
      this.$store.dispatch("postRequest", ["personality-test", data]);
      this.nextSlide();
    },
    applySampleData(dataset) {
      this.responses = dataset;
      this.firstName = "Max";
      this.lastName = "Mustermann";
      this.email = "simon.kemmesies@gmx.de";
      this.currentSlideIndex = 11;
      this.nextSlide();
    }
  }
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}

.text-h6 {
  margin-bottom: 5px;
}

.slides-container {
  display: grid;
}

.slides-container > div {
  display: none;
  grid-column: 1;
  grid-row: 1;
}

.position-right {
  transition: transform 225ms ease-out, opacity 225ms ease-out;
  transform: translateX(100%);
  opacity: 0;
}

.position-left {
  transition: transform 225ms ease-out, opacity 225ms ease-out;
  transform: translateX(-100%);
  opacity: 0;
}

.slide-in {
  transform: translateX(0) !important;
  opacity: 1;
}

.slide-out-left {
  transition: transform 195ms ease-in, opacity 195ms ease-in;
  transform: translateX(-100%) !important;
  opacity: 0;
}

.slide-out-right {
  transition: transform 195ms ease-in, opacity 195ms ease-in;
  transform: translateX(100%) !important;
  opacity: 0;
}

.background-element {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.background-image {
  filter: saturate(80%) contrast(50%) brightness(80%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.background-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 10%, rgba(0, 0, 0, 0) 55%);
}

</style>

<style>

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).disabled-btn-on-dark-background {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

</style>