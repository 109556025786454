<template>

  <div>

    <div v-if="section === 'taskStageOne'">
      Wähle aus, welche der folgenden Aussagen am häufigsten und welche am seltensten auf dich zutrifft.
    </div>

    <div v-if="section === 'taskStageTwo'">
      Wähle die drei Aussagen aus, die am besten zu dir passen.
    </div>

    <div v-if="taskId === 1 && section === 'situation'">
      Stell dir vor, du trittst einer Gruppe von Menschen bei, die dir vollkommen fremd sind.<br>
      Wie fühlst du dich in einer solchen Situation?<br>
      Was ist dein typisches Verhalten in solchen Momenten?<br>
      Versuche dich an frühere Gelegenheiten zu erinnern, bei denen du dich unter Fremden befunden hast - vielleicht am
      Anfang eines Seminars, auf einer Konferenz, auf einem Empfang, bei einer privaten Einladung oder auf einer Party,
      oder als Neuer in einer neuen Arbeitsumgebung.<br>
    </div>

    <div v-if="taskId === 1 && section === 'a'">
      Unter fremden Menschen fühle ich mich oft unbehaglich, ich halte mich zunächst lieber im Hintergrund.
    </div>

    <div v-if="taskId === 1 && section === 'b'">
      In geselligem Kreise fühle ich mich sofort sehr wohl. Auch zu Fremden finde ich rasch guten Kontakt.
    </div>

    <div v-if="taskId === 1 && section === 'c'">
      Ich bin in einer Gruppe oft derjenige, der "das Sagen hat". Bei Unternehmungen übernehme ich gern die Führung.
    </div>

    <div v-if="taskId === 2 && section === 'situation'">
      Wie reagierst du typischerweise im Beruf oder im Privatleben auf Ärger und Aufregung?<br>
      Bitte beachte, dass es hier nicht um Ausnahmefälle geht, in denen jedem "der Kragen platzt", sondern um den ganz
      alltäglichen Ärger, um den man nicht herumkommen kann.
    </div>

    <div v-if="taskId === 2 && section === 'a'">
      Wenn ich mich ärgere, reagiere ich oft impulsiv und versuche, mich körperlich abzureagieren.
    </div>

    <div v-if="taskId === 2 && section === 'b'">
      In solchen Momenten fühle ich mich auch dazu verpflichtet, meine Gefühle mit anderen zu teilen und alles, was mich
      stört, loszuwerden.
    </div>

    <div v-if="taskId === 2 && section === 'c'">
      Ich versuche meinen Ärger und meine Aufregung nicht zu zeigen und damit alleine fertig zu werden. Am liebsten
      ziehe ich mich dann zurück und vermeide soziale Kontakte.
    </div>

    <div v-if="taskId === 3 && section === 'situation'">
      Welche Lebensphase empfindest du als die schönste? Die Kindheit, die Jugend oder das Erwachsenenalter sind doch
      recht unterschiedliche Lebensabschnitte.
    </div>

    <div v-if="taskId === 3 && section === 'a'">
      Die Kindheit ist eine wunderschöne Zeit, in der man die Geborgenheit und Sorglosigkeit genießen kann. Ich komme
      gut mit Kindern zurecht und sie mögen mich auch sofort.
    </div>

    <div v-if="taskId === 3 && section === 'b'">
      Es wäre schön, wenn man die Jugendzeit, in der man die Welt erkundet und sich selbst besser versteht, möglichst
      lange ausdehnen könnte, denn in dieser Zeit genießt man das Leben in vollen Zügen.
    </div>

    <div v-if="taskId === 3 && section === 'c'">
      Je älter ich werde, umso mehr Abstand gewinne ich zu den Dingen, was dazu beiträgt, dass ich mich besser mit mir
      selbst und der Welt um mich herum auseinandersetze.
    </div>

    <div v-if="taskId === 4 && section === 'situation'">
      Menschen sind alle verschieden und zeigen ihre Unterschiedlichkeit in ihrem Verhalten und ihrer Art, sich zu
      geben. Diese Unterschiede werden von der Umwelt schon beim ersten Eindruck wahrgenommen.<br>
      Je besser man jemanden kennt, desto mehr Eigenarten und Charakterzüge werden deutlich. In diesem Zusammenhang geht
      es um die Wirkung, die man beim ersten Eindruck auf die Umwelt hat.<br>
      Was glaubst du, wie du von deiner Umwelt wahrgenommen wirst?
    </div>

    <div v-if="taskId === 4 && section === 'a'">
      Ich wirke auf andere Menschen gemütlich und gesellig.
    </div>

    <div v-if="taskId === 4 && section === 'b'">
      Ich wirke lebhaft und impulsiv.
    </div>

    <div v-if="taskId === 4 && section === 'c'">
      Ich werde als still und zurückhaltend wahrgenommen.
    </div>

    <div v-if="taskId === 5 && section === 'situation'">
      Jeder Mensch hat seine eigene Art, seine Zeit zu organisieren.<br>
      Wie teilst du dir deine Zeit ein?
    </div>

    <div v-if="taskId === 5 && section === 'a'">
      Ich habe häufig das Problem, dass ich die Zeit vergesse und dadurch zu spät zu Verabredungen und Terminen komme.
    </div>

    <div v-if="taskId === 5 && section === 'b'">
      Ich bin dafür bekannt, meine Termine oft erst in letzter Minute zu erreichen und bei Verabredungen oft der Letzte zu sein.
    </div>

    <div v-if="taskId === 5 && section === 'c'">
      Ich habe Angst, mich bei Verabredungen und Terminen zu verspäten und komme deshalb aus Vorsicht oft zu früh.
    </div>

    <div v-if="taskId === 6 && section === 'situation'">
      Wie verbringst du deine Freizeit? Also die Zeit, in der du wirklich keine Pflichten hast und tun kannst, was du willst?
    </div>

    <div v-if="taskId === 6 && section === 'a'">
      Ich bevorzuge es, ungestört meinen eigenen Interessen nachzugehen.
    </div>

    <div v-if="taskId === 6 && section === 'b'">
      Ich faulenze gern oder verbringe Zeit in netter Gesellschaft.
    </div>

    <div v-if="taskId === 6 && section === 'c'">
      Ich habe ständig das Bedürfnis, etwas zu tun und kann es nicht lange ohne Aktivität aushalten.
    </div>

    <div v-if="taskId === 7 && section === 'situation'">
      Welche der hier aufgeführten Situationen ist für dich unerträglich?<br>
      Was ist für dich lebenswichtig?
    </div>

    <div v-if="taskId === 7 && section === 'a'">
      Es ist für mich unerträglich, wenn ich in engen Räumen eingesperrt bin. Bewegungsfreiheit ist für mich von lebenswichtiger Bedeutung.
    </div>

    <div v-if="taskId === 7 && section === 'b'">
      Es ist für mich unerträglich, von Kontakten zur Aussenwelt abgeschnitten und von Menschen verlassen zu sein. Kontakte zu Menschen sind für mich lebenswichtig.
    </div>

    <div v-if="taskId === 7 && section === 'c'">
      Für mich ist es unerträglich, immer unter Menschen sein zu müssen und mich nirgends zurückziehen zu können. Meine Privatsphäre ist von lebenswichtiger Bedeutung für mich.
    </div>

    <div v-if="taskId === 8 && section === 'a'">
      Ich sage direkt, was ich denke und bin nicht immer sehr taktvoll.
    </div>

    <div v-if="taskId === 8 && section === 'b'">
      Es fällt mir schwer, meine Gefühle zum Ausdruck zu bringen, weshalb ich oft falsch eingeschätzt werde.
    </div>

    <div v-if="taskId === 8 && section === 'c'">
      Ich habe ein großes Interesse an Menschen und ihrem Leben und ihren Lebensumständen.
    </div>

    <div v-if="taskId === 8 && section === 'd'">
      Meine Liebe gilt dem Risiko und Abenteuer. Meine Devise lautet: Mut zahlt sich aus.
    </div>

    <div v-if="taskId === 8 && section === 'e'">
      Ich bin sehr empfindlich gegen Lärm und verhalte mich meistens leise.
    </div>

    <div v-if="taskId === 8 && section === 'f'">
      Ich bin grundsätzlich ein Feinschmecker, da ich eine leckere Küche liebe.
    </div>

    <div v-if="taskId === 9 && section === 'a'">
      Ich bevorzuge Frieden und versuche, verständnisvoll und ausgleichend zu sein.
    </div>

    <div v-if="taskId === 9 && section === 'b'">
      Ich treffe schnelle Entscheidungen und zögere nicht lange.
    </div>

    <div v-if="taskId === 9 && section === 'c'">
      Ich bin sehr sorgfältig, auch bei kleineren Dingen.
    </div>

    <div v-if="taskId === 9 && section === 'd'">
      Ich tendiere dazu, mir das Leben unnötig schwer zu machen, indem ich zu viel nachdenke und zweifle.
    </div>

    <div v-if="taskId === 9 && section === 'e'">
      Ich nehme trotz wenig Nahrungsaufnahme leicht zu.
    </div>

    <div v-if="taskId === 9 && section === 'f'">
      Um mich wohl zu fühlen, brauche ich immer viel Bewegung.
    </div>

    <div v-if="taskId === 10 && section === 'a'">
      In den meisten Fällen bevorzuge ich es, nur zuzuschauen statt mitzumachen.
    </div>

    <div v-if="taskId === 10 && section === 'b'">
      Es macht mich wirklich wütend, wenn ich auf etwas warten muss.
    </div>

    <div v-if="taskId === 10 && section === 'c'">
      Ich neige dazu, heftig und aggressiv zu reagieren, wenn ich mich angegriffen fühle.
    </div>

    <div v-if="taskId === 10 && section === 'd'">
      Ich bin immer dabei und will kein Party-Killer sein.
    </div>

    <div v-if="taskId === 10 && section === 'e'">
      Ich bilde mir zwar eine eigene Meinung, halte sie aber in der Regel zurück.
    </div>

    <div v-if="taskId === 10 && section === 'f'">
      Ich neige dazu, Entscheidungen nach der Meinung anderer auszurichten.
    </div>

  </div>


</template>

<script>
export default {
  name: "PtText",
  props: {
    taskId: {
      type: Number,
      required: false
    },
    section: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped>

</style>
